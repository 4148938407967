import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Outlet } from 'react-router-dom'
import Login from './login'
import { getDataToLocalStore, LocalStorageKey } from '../helpers/localStorage'

const Page = () => {
  const accessTokenGg = getDataToLocalStore(LocalStorageKey.ACCESS_TOKEN_GG)

  return (
    <>
      {accessTokenGg ? (
        <div className="bg-neutral-8 flex flex-col h-[100vh] max-w-[1440px] m-auto overflow-auto">
          <Header />
          <div className="flex-1 pt-[72px] bg-neutral-8">
            <Outlet />
          </div>
          <Footer />
        </div>
      ) : (
        <Login />
      )}
    </>
  )
}

export default Page
