export enum LocalStorageKey {
  ACCESS_TOKEN = 'accessToken',
  USER_INFO = 'userInfo',
  ACCESS_TOKEN_GG = 'accessTokenGg',
}

export const saveDataToLocalStore = (key: string, data: any) => {
  localStorage.setItem(key, data)
}

export const getDataToLocalStore = (key: string) => {
  return localStorage.getItem(key)
}

export const clearDataInLocalStore = () => {
  localStorage.clear()
}

export const removeDataInLocalStore = (key: string) => {
  localStorage.removeItem(key)
}
