import React from 'react'

const Overview = () => {
  return (
    <div className="px-20 my-10">
      <div className="grid justify-items-center">
        <p className="font-semibold text-headline1 text-neutral-3">Static document</p>
        <p className="font-medium text-body1 text-neutral-3">
          Documentation - Videos of a backup and recovery (and anylinks to our confluence page)
        </p>
      </div>
    </div>
  )
}

export default Overview
