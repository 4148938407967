import { AnyAction } from 'redux'
import {
  REQUEST_GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_SUCCESS,
  REQUEST_GET_USER_INFO,
} from './actionTypes'

//USER
export const requestGetUserById = (value: number): AnyAction => {
  return {
    type: REQUEST_GET_USER,
    payload: value,
  }
}

export const getUserByIdSuccess = (value: object | any): AnyAction => {
  return {
    type: GET_USER_SUCCESS,
    payload: value,
  }
}

export const getUserByIdFail = (value: any): AnyAction => {
  return {
    type: GET_USER_FAIL,
    payload: value,
  }
}

//USER
export const requestGetUserInfo = (value: number): AnyAction => {
  return {
    type: REQUEST_GET_USER_INFO,
    payload: value,
  }
}

export const getUserInfoSuccess = (value: object | any): AnyAction => {
  return {
    type: GET_USER_INFO_SUCCESS,
    payload: value,
  }
}

export const getUserInfoFail = (value: any): AnyAction => {
  return {
    type: GET_USER_INFO_FAIL,
    payload: value,
  }
}
