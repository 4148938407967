import React from 'react'
import { LoginSocialGoogle } from 'reactjs-social-login'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { images } from '../../assets/images'
import { refreshTokenSetup } from '../../helpers/refreshToken'
import { saveDataToLocalStore, LocalStorageKey } from '../../helpers/localStorage'
import { requestGetUserInfo } from '../../redux/actions/users'
import { API_ENDPOINTS } from '../../services'
import { BASE_URL } from '../../network'

const clientId = process.env.REACT_APP_DEVELOPMENT_CLIENT_ID || ''

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const url = BASE_URL + API_ENDPOINTS.auth.login

  const onSuccessWithSocialLogin = async (res: any) => {
    await dispatch(requestGetUserInfo(res.data))
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${res.data.access_token}`,
      },
      method: 'POST',
    })
      .then((_response) => {
        refreshTokenSetup(res)
        saveDataToLocalStore(LocalStorageKey.USER_INFO, JSON?.stringify(res.data))
        saveDataToLocalStore(LocalStorageKey.ACCESS_TOKEN_GG, res.data?.access_token)
        navigate('/', { replace: true })
        window.location.reload()
      })
      .catch((_error) => {})
  }

  return (
    <div className="grid w-screen h-screen place-items-center">
      <div className="w-[394px] h-[276px] rounded-2xl shadow-2xl">
        <img src={images.logoMedium} alt="logo" className="m-auto mt-8" />
        <LoginSocialGoogle
          client_id={clientId}
          onLoginStart={() => onSuccessWithSocialLogin}
          onResolve={(res) => {
            onSuccessWithSocialLogin(res)
          }}
          onReject={(err) => {
            console.log(err)
          }}
          scope="openid profile email"
        >
          <button className="w-[346px] rounded-xl border-primary-1 border-[2px] my-8 m-auto flex cursor-pointer">
            <img src={images.iconGG} alt="icon" className="m-1 rounded-xl" />
            <div id="customBtn" className="w-full h-[48px] bg-primary-1 rounded-r-[8px]">
              <p className="mt-4 text-center text-hairline1 text-neutral-8">Sign in with Google</p>
            </div>
          </button>
        </LoginSocialGoogle>
      </div>
    </div>
  )
}

export default Login
