import React, { useState } from 'react'
import Tabs, { TabsType } from '../../components/partials/tabs'
import BackUpAndRestore from './components/backupAndRestore'
import Overview from './components/overview'
import ServerManagement from './components/serverMng'

// Tabs Array
const tabs: TabsType = [
  {
    label: 'Overview',
    index: 1,
    Component: Overview,
  },
  {
    label: 'Backup & Restore',
    index: 2,
    Component: BackUpAndRestore,
  },
  {
    label: 'Server management',
    index: 3,
    Component: ServerManagement,
  },
]

const ControlPanel = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)
  return (
    <div>
      <Tabs
        needResponsive={true}
        selectedTab={selectedTab}
        pageName="Admin portal"
        onClick={setSelectedTab}
        tabs={tabs}
      />
    </div>
  )
}

export default ControlPanel
