import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { images } from '../../assets/images'
import { RootState } from '../../store'
import { getDataToLocalStore, LocalStorageKey } from '../../helpers/localStorage'
import ButtonSignOut from '../sign-out'

const Header = () => {
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const dropDownMenuRef = useRef<HTMLUListElement>(null)
  const [isPopupDropDown, setIsPopupDropDown] = useState<boolean>(false)
  const btnRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [isPopup, setIsPopup] = useState<boolean>(false)
  const [avatar, setAvatar] = useState<string>(images.avatarDefault)

  const accessTokenGg = getDataToLocalStore(LocalStorageKey.ACCESS_TOKEN_GG)

  const handleClick = () => {
    setIsPopup(!isPopup)
  }

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        menuRef &&
        !menuRef.current?.contains(e.target) &&
        btnRef &&
        !btnRef.current?.contains(e.target)
      ) {
        setIsPopup(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      // Remove click outside
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isPopup])

  useEffect(() => {
    const handleOutsideDropDownClick = (e: any) => {
      if (
        dropDownMenuRef &&
        !dropDownMenuRef.current?.contains(e.target) &&
        btnRef &&
        !dropDownRef.current?.contains(e.target)
      ) {
        setIsPopupDropDown(false)
      }
    }

    document.addEventListener('click', handleOutsideDropDownClick)

    return () => {
      document.removeEventListener('click', handleOutsideDropDownClick)
    }
  }, [isPopupDropDown])

  useEffect(() => {
    setAvatar(userInfoState?.picture)
  }, [userInfoState])

  return (
    <header className="w-full max-w-[1440px] flex items-center px-20 h-[72px] justify-between border-b border-b-neutral-6 bg-neutral-7-header-footer fixed z-20">
      <div className="flex items-center h-full">
        <NavLink to="/">
          <img
            src={images.logoBig}
            alt="Logo"
            className="max-w-[110px] h-[40px] mr-5 md:ml-[-40px]"
          />
        </NavLink>
      </div>
      <div className="flex items-center">
        <div className="mr-6">
          <ButtonSignOut className="justify-start hover:bg-transparent" />
        </div>
        {accessTokenGg && (
          <>
            <div className="relative">
              <div
                ref={btnRef}
                onClick={handleClick}
                className="flex items-center rounded-full cursor-pointer md:mr-[-42px]"
              >
                <img className="max-w-[40px] h-10 rounded-full" src={avatar} alt="Avatar" />
              </div>
              {isPopup && (
                <div
                  ref={menuRef}
                  className="absolute right-0 mt-2 md:mr-[-40px] rounded-xl bg-neutral-8 p-3 w-60 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-7"
                >
                  <div className="p-2 pb-3 text-neutral-3">
                    <p className="font-semibold text-body1">{`${userInfoState?.given_name} ${userInfoState?.family_name}`}</p>
                    <p className="font-medium text-body3">Admin</p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </header>
  )
}

export default Header
