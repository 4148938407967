import Page from '.'
import controlPanelRoutes from './control-panel/controlPanelRoutes'

const pageRoutes = [
  {
    path: '/',
    element: Page,
    isProtected: false,
    children: [...controlPanelRoutes],
  },
]

export default pageRoutes
