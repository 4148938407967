export const MESSAGES = {
  SUCCESS: {},
  WARNING: {},
  ERROR: {},
  INFO: {},
}

export enum STATUS {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}
