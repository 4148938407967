import ControlPanel from '.'
import { PageRoute } from '../../routes/routerInterface'

const controlPanelRoutes: PageRoute[] = [
  {
    path: '/',
    element: ControlPanel,
    isProtected: false,
  },
]

export default controlPanelRoutes
