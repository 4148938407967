import React, { FC, useEffect } from 'react'

export type TabsType = {
  label: string
  index: number
  Component: React.FC<{ index: number }>
}[]

export type TabsProps = {
  tabs: TabsType
  selectedTab: number
  onClick: (index: number) => void
  orientation?: 'horizontal' | 'vertical'
  className?: string
  pageName: string
  pageNameParent?: string
  carrier?: any
  goBackURL?: string
  needResponsive?: boolean
}

/**
 * Avalible Props
 * @param className string
 * @param tab Array of object
 * @param selectedTab select current tab
 * @param onClick Function to set the active tab
 * @param orientation Tab orientation Vertical | Horizontal
 * @param pageName Name of page parent
 * @param carrier Name of  carrier
 * @param goBack back to this page
 */

/** */
const Tabs: FC<TabsProps> = ({
  className = '',
  tabs = [],
  selectedTab = 0,
  onClick,
  orientation = 'horizontal',
}) => {
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab)
  const activeTabClass = 'bg-primary-shade4 text-primary-shade1'
  const inActiveTabClass = 'text-neutral-4 bg-transparent'

  const handleClick = (tab: any) => {
    onClick(tab.index)
    const element = document.getElementById(`btn-${tab.index}`) as HTMLButtonElement
    element.scrollIntoView({ block: 'center', inline: 'center', behavior: 'auto' })
  }

  useEffect(() => {}, [selectedTab])

  return (
    <div className={`${orientation === 'vertical' && 'vertical'} ${className}`}>
      <div className="px-20 md:px-10">
        <div
          role="tablist"
          className="flex py-4 overflow-x-hidden tabscroll max-w-1024"
          // onWheel={handleOnWheel}
        >
          {tabs.map((tab) => (
            <button
              className={`px-6 mr-2 last:mr-0 py-2 text-body1 font-semibold cursor-pointer rounded-full text-center whitespace-nowrap ${
                selectedTab === tab.index ? activeTabClass : inActiveTabClass
              }`}
              onClick={() => handleClick(tab)}
              key={tab.index}
              type="button"
              role="tab"
              aria-selected={selectedTab === tab.index}
              aria-controls={`tabpanel-${tab.index}`}
              tabIndex={selectedTab === tab.index ? 0 : -1}
              id={`btn-${tab.index}`}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <hr className="border-t-neutral-5" />
      </div>
      <div role="tabpanel" aria-labelledby={`btn-${selectedTab}`} id={`tabpanel-${selectedTab}`}>
        {Panel && <Panel.Component index={selectedTab} />}
      </div>
    </div>
  )
}

export default Tabs
