import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { images } from '../../../assets/images'
import CheckBoxBase from '../../../components/partials/toggleBase'
import { BASE_URL } from '../../../network'
import { API_ENDPOINTS } from '../../../services'
import axiosService from '../../../services/axiosServices'

const dataServer = [
  {
    env: 'Production environment',
    urlSandbox: process.env.REACT_APP_DEVELOPMENT_SERVER_PROD_SANDBOX,
    urlProd: process.env.REACT_APP_DEVELOPMENT_SERVER_PROD_PRODUCTION,
    goliveSandbox: 'goliveSandbox',
    goliveProduction: 'goliveProduction',
  },
  {
    env: 'UAT environment',
    urlSandbox: process.env.REACT_APP_DEVELOPMENT_SERVER_UAT_SANDBOX,
    urlProd: process.env.REACT_APP_DEVELOPMENT_SERVER_UAT_PRODUCTION,
    uatSandbox: 'uatSandbox',
    uatProduction: 'uatProduction',
  },
  {
    env: 'QA environment',
    urlSandbox: process.env.REACT_APP_DEVELOPMENT_SERVER_QA_SANDBOX,
    urlProd: process.env.REACT_APP_DEVELOPMENT_SERVER_QA_PRODUCTION,
    qaSandbox: 'qaSandbox',
    qaProduction: 'qaProduction',
  },
  {
    env: 'Development environment',
    urlSandbox: process.env.REACT_APP_DEVELOPMENT_BACKUP_DEV,
    urlProd: process.env.REACT_APP_DEVELOPMENT_SERVER_DEV_PRODUCTION,
    devSandbox: 'devSandbox',
    devProduction: 'devProduction',
  },
]

const msg = {
  On: 'ON',
  Off: 'OFF',
}

const ServerManagement = () => {
  const [isEnv, setIsEnv] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const url_getAllStatusEnv = BASE_URL + API_ENDPOINTS.userManagement.getListStatusEnv
  const url_updateService = BASE_URL + API_ENDPOINTS.userManagement.updateDefaultService

  const getAllStatuses = () => {
    setIsLoading(true)
    axiosService
      .post({}, url_getAllStatusEnv)
      .then(function (response: any) {
        setIsEnv(response.data)
        setIsLoading(false)
      })
      .catch(function (error: any) {
        setIsLoading(false)
      })
  }

  const redirectToMaintenance = (status?: boolean, server?: string) => {
    let env = null
    const envGoLiveSandbox = {
      goliveSandbox: status,
    }
    const envUatSandbox = {
      uatSandbox: status,
    }
    const envQaSandbox = {
      qaSandbox: status,
    }
    const envDevSandbox = {
      devSandbox: status,
    }
    const envGoLiveProduction = {
      goliveProduction: status,
    }
    const envUatProduction = {
      uatProduction: status,
    }
    const envQaProduction = {
      qaProduction: status,
    }
    const envDevProduction = {
      devProduction: status,
    }
    switch (server) {
      case 'goliveSandbox':
        env = envGoLiveSandbox
        break
      case 'uatSandbox':
        env = envUatSandbox
        break
      case 'qaSandbox':
        env = envQaSandbox
        break
      case 'devSandbox':
        env = envDevSandbox
        break
      case 'goliveProduction':
        env = envGoLiveProduction
        break
      case 'uatProduction':
        env = envUatProduction
        break
      case 'qaProduction':
        env = envQaProduction
        break
      case 'devProduction':
        env = envDevProduction
        break
      default:
        env = {}
        break
    }
    setIsLoading(true)
    axiosService
      .post(env, url_updateService)
      .then(function (response: any) {
        setIsEnv(response)
        setIsLoading(false)
        getAllStatuses()
      })
      .catch(function (error: any) {
        setIsLoading(false)
        getAllStatuses()
      })
  }

  useEffect(() => {
    getAllStatuses()
  }, [])

  const renderRedirectSandboxSite = (idx: number) => {
    return (
      <div>
        {idx === 0 ? (
          <div className="flex">
            <p className="pt-2 pr-2 font-semibold text-hairline2 text-neutral-3">
              {isEnv?.goliveSandbox ? msg.On : msg.Off}
            </p>
            <CheckBoxBase
              value={isEnv?.goliveSandbox}
              handleToggle={() => redirectToMaintenance(!isEnv?.goliveSandbox, 'goliveSandbox')}
            />
          </div>
        ) : idx === 1 ? (
          <div className="flex">
            <p className="pt-2 pr-2 font-semibold text-hairline2 text-neutral-3">
              {isEnv?.uatSandbox ? msg.On : msg.Off}
            </p>
            <CheckBoxBase
              value={isEnv?.uatSandbox}
              handleToggle={() => redirectToMaintenance(!isEnv?.uatSandbox, 'uatSandbox')}
            />
          </div>
        ) : idx === 2 ? (
          <div className="flex">
            <p className="pt-2 pr-2 font-semibold text-hairline2 text-neutral-3">
              {isEnv?.qaSandbox ? msg.On : msg.Off}
            </p>
            <CheckBoxBase
              value={isEnv?.qaSandbox}
              handleToggle={() => redirectToMaintenance(!isEnv?.qaSandbox, 'qaSandbox')}
            />
          </div>
        ) : (
          <div className="flex">
            <p className="pt-2 pr-2 font-semibold text-hairline2 text-neutral-3">
              {isEnv?.devSandbox ? msg.On : msg.Off}
            </p>
            <CheckBoxBase
              value={isEnv?.devSandbox}
              handleToggle={() => redirectToMaintenance(!isEnv?.devSandbox, 'devSandbox')}
            />
          </div>
        )}
      </div>
    )
  }

  const renderRedirectProductionEnv = (idx: number) => {
    return (
      <div>
        {idx === 0 ? (
          <div className="flex">
            <p className="pt-2 pr-2 font-semibold text-hairline2 text-neutral-3">
              {isEnv?.goliveProduction ? msg.On : msg.Off}
            </p>
            <CheckBoxBase
              value={isEnv?.goliveProduction}
              handleToggle={() =>
                redirectToMaintenance(!isEnv?.goliveProduction, 'goliveProduction')
              }
            />
          </div>
        ) : idx === 1 ? (
          <div className="flex">
            <p className="pt-2 pr-2 font-semibold text-hairline2 text-neutral-3">
              {isEnv?.uatProduction ? msg.On : msg.Off}
            </p>
            <CheckBoxBase
              value={isEnv?.uatProduction}
              handleToggle={() => redirectToMaintenance(!isEnv?.uatProduction, 'uatProduction')}
            />
          </div>
        ) : idx === 2 ? (
          <div className="flex">
            <p className="pt-2 pr-2 font-semibold text-hairline2 text-neutral-3">
              {isEnv?.qaProduction ? msg.On : msg.Off}
            </p>
            <CheckBoxBase
              value={isEnv?.qaProduction}
              handleToggle={() => redirectToMaintenance(!isEnv?.qaProduction, 'qaProduction')}
            />
          </div>
        ) : (
          <div className="flex">
            <p className="pt-2 pr-2 font-semibold text-hairline2 text-neutral-3">
              {isEnv?.devProduction ? msg.On : msg.Off}
            </p>
            <CheckBoxBase
              value={isEnv?.devProduction}
              handleToggle={() => redirectToMaintenance(!isEnv?.devProduction, 'devProduction')}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="px-20 my-10">
      {isLoading ? (
        <div className="flex justify-center p-5">
          <ReactLoading type="spin" color="#A989D8" height={30} width={30} />
        </div>
      ) : (
        <div className="ml-4">
          {dataServer.map((item, idx) => (
            <div key={idx}>
              <div className="flex justify-between">
                <p className="text-headline5A">{item.env}</p>
                <p className="mt-[3px] font-semibold text-body1 text-neutral-4">Maintenance page</p>
              </div>
              <div>
                <div className="flex justify-between my-3 mt-5">
                  <div className="flex">
                    <div className="w-[3px] h-[3px] mt-4 mx-3 bg-neutral-3 rounded-xl" />
                    <span className="mt-1">Sandbox site</span>{' '}
                    <img
                      src={images.iconExpand}
                      alt="expand"
                      onClick={() => window.open(item.urlSandbox, '_blank')}
                      className="w-[22px] h-[22px] ml-3 mt-1 cursor-pointer"
                    />
                  </div>
                  <div className="flex">{renderRedirectSandboxSite(idx)}</div>
                </div>
                <div className="flex justify-between my-3 mt-6">
                  <div className="flex">
                    <div className="w-[3px] h-[3px] mt-4 mx-3 bg-neutral-3 rounded-xl" />
                    <span className="mt-1">Production environment</span>{' '}
                    <img
                      src={images.iconExpand}
                      alt="expand"
                      onClick={() => window.open(item.urlProd, '_blank')}
                      className="w-[22px] h-[22px] ml-3 mt-1 cursor-pointer"
                    />
                  </div>
                  <div className="flex">{renderRedirectProductionEnv(idx)}</div>
                </div>
              </div>
              <hr className={`${idx === 3 && 'hidden'} my-8`} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ServerManagement
