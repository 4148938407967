import React from 'react'
import { images } from '../../assets/images'

const Footer = () => {
  return (
    <div className="w-full max-w-[1440px] px-20 border-t mw926:px-10 bg-neutral-7-header-footer border-t-neutral-6">
      <div className="flex items-start justify-between pt-5">
        <img src={images.logoBig} alt="Logo Propeller" className="w-[220px] h-[80px]" />
        <div className="flex flex-col items-end pt-5 text-body1 text-neutral-2 lg:w-[496px]">
          <div className="flex flex-wrap items-baseline justify-end pb-3 mb-3 border-b-2 md:flex-col md:items-end border-b-neutral-6">
            <a href="tel:815-595-2088" className="flex items-center">
              <img src={images.iconPhone} alt="Icon Phone" className="inline mr-2" /> 815 595 2088
            </a>
            <a
              href="https://www.propellerbonds.com"
              target="_blank"
              rel="noreferrer"
              className="flex items-center ml-10 mw1314:py-4"
            >
              <img src={images.iconPropellerWeb} alt="Icon Web" className="inline mr-2" />{' '}
              Propellerbonds website
            </a>
            <a
              href="https://propellerapi.uat.getpropellerbonds.com/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center ml-10 lg:ml-0 md:pb-4"
            >
              <img src={images.iconWeb} alt="Icon Web" className="inline mr-2" /> Production website
            </a>
            <p className="flex items-center ml-10 md:ml-0">
              <img src={images.iconTerm} alt="Icon Term" className="inline mr-2" />
              <a
                href="https://www.propellerbonds.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy
              </a>
              <span className="h-4 w-[1px] mx-2 bg-neutral-6"></span>
              <a
                href="https://www.propellerbonds.com/terms-conditions/"
                target="_blank"
                rel="noreferrer"
              >
                Terms &#38; Conditions
              </a>
            </p>
          </div>
          <div className="flex">
            <a href="mailto:apis@propellerbonds.com" className="ml-6">
              <img src={images.iconGmail} alt="Icon Gmail" height={'24px'} />
            </a>
            <a
              href="https://www.linkedin.com/company/propellerbonds/"
              target="_blank"
              className="ml-6"
              rel="noreferrer"
            >
              <img src={images.iconLinkedin} alt="Icon Linkedin" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100076601568684"
              target="_blank"
              className="ml-6"
              rel="noreferrer"
            >
              <img src={images.iconFb} alt="Icon Facebook" />
            </a>
            <a
              href="https://twitter.com/propeller_bonds"
              target="_blank"
              className="ml-6"
              rel="noreferrer"
            >
              <img src={images.iconTwitter} alt="Icon Twitter" />
            </a>
          </div>
          <p className="pt-10 pb-2 font-medium text-right text-body3 text-neutral-4">
            © 2022 | <span className="text-primary-shade2">Propeller</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
