import avatarDefault from './avatar_default.svg'
import logoBig from './logo_big.svg'
import logoMedium from './logo_medium.svg'
import iconPhone from './icon_phone.svg'
import iconFb from './fb.svg'
import iconGmail from './gmail.svg'
import iconLinkedin from './linkedin.svg'
import iconTwitter from './twitter.svg'
import iconWeb from './web.svg'
import iconPropellerWeb from './propeller_web.svg'
import iconTerm from './term.svg'
import iconClose from './icon_close.svg'
import iconCloseBlack from './icon_close_black.svg'
import iconLogout from './log_out.svg'
import iconCloudUpload from './icon_cloud_upload.svg'
import iconExpand from './icon_expand.svg'
import iconGG from './icon_gg.svg'

export const images = {
  avatarDefault,
  logoBig,
  logoMedium,
  iconPhone,
  iconFb,
  iconGmail,
  iconLinkedin,
  iconTwitter,
  iconWeb,
  iconPropellerWeb,
  iconTerm,
  iconClose,
  iconCloseBlack,
  iconLogout,
  iconCloudUpload,
  iconExpand,
  iconGG,
}
