export const API_ENDPOINTS = {
  users: {
    getUserById: '/users?user=',
  },
  auth: {
    login: '/login',
  },
  userManagement: {
    getListStatusEnv: '/maintenance/listStatusEnv',
    updateDefaultService: '/maintenance/updateDefaultService',
  },
}
