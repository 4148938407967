import { LocalStorageKey, saveDataToLocalStore } from './localStorage'

export const refreshTokenSetup = (res: any) => {
  // timing tor renew access token
  let refreshTiming = (res.data.expires_in || 3600 - 5 * 60) * 1000

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse()
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000
    // saveUserToken(newAuthRes.access_token) <-- save new token
    // setup the other timer after the first one
    setTimeout(refreshToken, refreshTiming)
  }

  // setup first refresh timer
  setTimeout(refreshToken, refreshTiming)

  // save data to localStorage when refresh token
  saveDataToLocalStore(LocalStorageKey.USER_INFO, JSON?.stringify(res.data))
  saveDataToLocalStore(LocalStorageKey.ACCESS_TOKEN_GG, res.data?.access_token)
}
