import React from 'react'
import { useNavigate } from 'react-router-dom'
import { images } from '../../assets/images'
import { clearDataInLocalStore } from '../../helpers/localStorage'

interface Props {
  className?: string
}

const ButtonSignOut: React.FC<Props> = () => {
  const navigate = useNavigate()

  const onHandleSignOut = async () => {
    clearDataInLocalStore()
    navigate('/', { replace: true })
    window.location.reload()
  }

  return (
    <div
      onClick={() => onHandleSignOut()}
      className={`bg-transparent flex items-center w-full border-2 py-2 px-4 rounded-xl cursor-pointer`}
    >
      <img src={images.iconLogout} alt="" className="pr-3.5" />{' '}
      <p className="font-medium text-neutral-4">Log out</p>
    </div>
  )
}

export default ButtonSignOut
