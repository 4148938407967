import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageRoute } from './routerInterface'

type RouterProps = {
  routes: PageRoute[]
}

const renderRoute = (routes: PageRoute[]) => {
  return routes.map((route: PageRoute, index: number) => {
    // Redirect router
    if (route.redirect && !route.element) {
      route.element = () => <Navigate to={route?.redirect || ''} />
    }

    const ContentRoute = route.element as React.ElementType

    return (
      <Route key={index} path={route.path} element={<ContentRoute />}>
        {route.children && renderRoute(route.children)}
      </Route>
    )
  })
}

export const RouterOutlet: FC<RouterProps> = ({ routes }) => {
  return <Routes>{renderRoute(routes)}</Routes>
}
