import React from 'react'
import { images } from '../../../assets/images'

const dataBackup = [
  {
    env: 'Production environment',
    url: process.env.REACT_APP_DEVELOPMENT_BACKUP_PROD,
  },
  {
    env: 'UAT environment',
    url: process.env.REACT_APP_DEVELOPMENT_BACKUP_UAT,
  },
  {
    env: 'QA environment',
    url: process.env.REACT_APP_DEVELOPMENT_BACKUP_QA,
  },
  {
    env: 'Development environment',
    url: process.env.REACT_APP_DEVELOPMENT_BACKUP_DEV,
  },
]

const BackUpAndRestore = () => {
  return (
    <div className="px-20 my-10">
      {/* <div className="flex ml-2">
        <img src={images.iconCloudUpload} alt="cloud-upload" />
        <p className="ml-4">
          Most recent backup <span className="font-bold">01/09/2023 - 09:29</span>
        </p>
      </div> */}
      <div className="mt-8">
        <p className="mb-3 text-headline5A">Access Google Backup</p>
        <div className="ml-4">
          {dataBackup.map((item, idx) => (
            <div key={idx}>
              <p className="!font-semibold text-body1">{item.env}</p>
              <div>
                <div className="flex my-2">
                  <div className="w-[3px] h-[3px] mt-[10px] mx-3 bg-neutral-3 rounded-xl" />
                  <span>Sandbox site</span>{' '}
                  <img
                    src={images.iconExpand}
                    alt="expand"
                    onClick={() => window.open(item.url, '_blank')}
                    className="ml-3 cursor-pointer"
                  />
                </div>
                <div className="flex my-2">
                  <div className="w-[3px] h-[3px] mt-[10px] mx-3 bg-neutral-3 rounded-xl" />
                  <span>Production site</span>{' '}
                  <img
                    src={images.iconExpand}
                    alt="expand"
                    onClick={() => window.open(item.url, '_blank')}
                    className="ml-3 cursor-pointer"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BackUpAndRestore
