import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'
import appRoutes from './appRoutes'
import { RouterOutlet } from './routes/routerOutlet'
import BaseToast from './components/toastify'
import { client } from './network/graphql'
import './index.css'

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <BaseToast />
            <RouterOutlet routes={appRoutes} />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  )
}

export default App
